import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'
import { AiOutlineCloseSquare } from "react-icons/ai"
import { GiHamburgerMenu } from 'react-icons/gi'


export const navbarProps = {
    navItems: [
        { link: "/", name: "Home" },
        { link: "/about", name: "About Us" },
        { link: "/planning", name: "Planning" },
        { link: "/ourwork", name: "Our Work" },
        { link: "/contact", name: "Contact" }
    ]
}
const Navbar = () => {
    const location = useLocation()
    const [currentLocation, setCurrentLocation] = useState("")
    const [isExpanded, toggleExpansion] = useState(false)

    useEffect(() => {
        const currentPath = location.pathname;
        setCurrentLocation(currentPath)
    }, [location]);

    const navBarLinkClasses = "mx-4 hover:text-blue-700 whitespace-no-wrap"

    return (
        <>
            <nav className="`${ isExpanded ? `block` : `hidden` } flex sm:justify-end justify-around text-blue-800 font-bold px-6 mx-20 sm:flex-wrap mb-4">
                {navbarProps.navItems.map((item, index) => {
                    return <Link key={index} className={`lg:block ${navBarLinkClasses} hidden`} to={item.link} activeClassName="border-solid border-b-4 border-gray-300">
                        {item.link === currentLocation && currentLocation === "/" ? null : item.name}</Link>
                })}
            </nav>
            <div className="lg:hidden flex flex-col items-end">
                <button aria-label="Mobile Navigation" onClick={() => toggleExpansion(!isExpanded)} className={`flex items-center my-4 mr-2 px-2 py-1 border-2 rounded ${isExpanded ? `text-gray-400` : `text-blue-800 border-blue-800 hover:text-blue-600 hover:border-blue-700`} `}>
                    {isExpanded ? <AiOutlineCloseSquare className="fixed top-0 -mx-20 my-10 z-40 text-blue-800 text-6xl" /> : <GiHamburgerMenu />}
                </button>
                <div className={`${isExpanded ? `block` : `hidden`}  flex flex-col items-end`}>
                    <div className="flex flex-col justify-center bg-gray-300  w-auto border border-gray-500 fixed top-0 right-0 left-0 bottom-0 h-full z-30">
                        {navbarProps.navItems.map((item, index) => {
                            return <Link key={index} className="flex flex-col items-center font-semibold whitespace-no-wrap text-5xl text-blue-900" to={item.link}>
                                {item.link === currentLocation && currentLocation === "/" ? null : item.name}</Link>
                        })}
                    </div>
                    <div></div>
                </div>
            </div>
        </>
    )
}

export default Navbar;