import React, { useState } from 'react'
import InfoBannerModal from '../components/Modals/InfoBannerModal'

export default function InfoBanner({ info }) {
    const [ modalInit, setModalInit] = useState(false)
    return (
        <>
            <div className="bg-blue-900 shadow-xl flex justify-between text-xs border-blue-900 border-b-2">
                {info.map((item, index) => {
                    return <p key={item} className="w-auto text-blue-100 px-6 py-2">{item}{index === 2 ? <span onClick={() => setModalInit(!modalInit)} className="text-blue-200 cursor-pointer hover:text-blue-100">**</span> : null}</p>
                })}
            </div>
            {/* Set toggle when user clicks conditions for visit */}
            <InfoBannerModal modalInit={modalInit} setModalInit={setModalInit} />
        </>
    )
}
