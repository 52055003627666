import React from 'react'
import { AiOutlineCloseSquare } from "react-icons/ai"
import { useStaticQuery, graphql } from 'gatsby'


export default function InfoBannerModal({ modalInit, setModalInit }) {
    const data = useStaticQuery(graphql`
    query {
        contentfulModal {
          title
          description {
            description
          }
        }
      }
      
    `)

    const { title, description: { description } } = data.contentfulModal
    function initializeModal() {
        if (modalInit) {
            return (
                <>
                    <div className="bg-gray-800 opacity-50 fixed top-0 right-0 left-0 bottom-0 h-full z-30" />
                    <div className="bg-blue-900 absolute inset-x-0 p-6 m-20 top-0 shadow-xl border border-gray-600 text-blue-100 z-40">
                        <div className="flex justify-end">
                            <AiOutlineCloseSquare onClick={() => setModalInit(!modalInit)} className="text-4xl hover:text-blue-700 cursor-pointer" />
                        </div>
                        <div className="py-6 text-center">
                            <div className="text-2xl pb-2 text-blue-200 font-semibold">{title}</div>
                            {description}
                        </div>
                    </div>
                </>
            )
        }
    }

    return (
        <>
            {initializeModal()}
        </>
    )
}
