import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import useSanitize from '.././hooks/useSanitize'
import { FaFacebookSquare, FaPhoneAlt, FaRegEnvelope, FaAngleUp } from 'react-icons/fa';
import { navbarProps } from '../components/Navbar'
import scrollTo from 'gatsby-plugin-smoothscroll'




export default function Footer() {
    const data = useStaticQuery(graphql`
 query {
    contentfulFooter {
      businessHours {
        childMarkdownRemark {
          html
        }
      }
      emailAddress
      telephoneNumber
      facebookLink
      location {
        lon
        lat
      }
    }
  }
    `)


    const { businessHours: { childMarkdownRemark: { html } }, telephoneNumber, emailAddress, facebookLink } = data.contentfulFooter

    const textSanitized = useSanitize(html)

    function displayMarkdown() {
        return { __html: textSanitized }
    }



    return (
        <footer className="bg-gray-200">
            <div className="flex flex-col justify-center items-center py-6">
                <span className="cursor-pointer hover:text-blue-700" onClick={() => scrollTo('header')}><FaAngleUp /></span>
            </div>
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-2 p-2">
                <div className="text-gray-900 flex flex-col items-center">
                    <div className="flex flex-col pb-2">
                        <div className="flex lg:text-base text-xs">
                            <FaPhoneAlt className="text-blue-800" />
                            <a href={`tel:${telephoneNumber}`} className="pl-4 font-semibold text-blue-700"> {telephoneNumber}</a>
                        </div>
                        <div className="flex flex-wrap lg:text-base text-xs text-blue-800 hover:text-blue-700">
                            <FaRegEnvelope className="fill-current" />
                            <span className="pl-4"><a href={`mailto:${emailAddress}`}>{emailAddress}</a></span>
                        </div>
                    </div>
                    <span className="p-4 font-bold text-2xl text-blue-900">Business Hours</span>
                    <div className="text-xl text-blue-900 pb-4" dangerouslySetInnerHTML={displayMarkdown()}>
                    </div>


                </div>

                <div className="mb-auto">
                    <div className="lg:flex flex-col lg:items-center lg:text-base text-center py-4">
                        <div className="grid lg:flex md:grid-cols-2 grid-cols-1">
                            {navbarProps.navItems.map((item, index) => {
                                return <Link key={index} className="text-blue-900 hover:text-blue-500 lg:text-xl text-xs font-semibold mb-6 px-4" to={item.link}>{item.name}</Link>
                            })}
                        </div>

                        <div className="text-gray-800 flex flex-col items-center">
                            <span className="text-md py-6">Find us on:</span>
                            <a className="hover:text-indigo-700 text-3xl" href={facebookLink} aria-label="Jon Spencer Technical Drawing on Facebook" target="_blank" rel="noreferrer"><FaFacebookSquare /></a>
                        </div>
                        <div className="flex flex-col text-center pt-6 text-xs text-blue-900">
                            <span>
                                &copy; Copyright {new Date().getFullYear()}
                    , Jon Spencer Technical Drawing Limited. All rights reserved.<span>
                                </span>
                            </span>
                            <p className="py-4">Website developed by <a className="hover:text-blue-700" aria-label="Red Bolt Studio" href="https://www.redboltstudio.com" target="_blank" rel="noreferrer">Red Bolt Studio.</a></p>
                        </div>
                    </div>
                </div>

            </div>
        </footer>
    )
}
