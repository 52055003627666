import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { FaFacebookSquare } from 'react-icons/fa';
import Navbar from './Navbar'
import InfoBanner from './InfoBanner'

const Header = () => {
    const data = useStaticQuery(graphql`
    query {
        contentfulHeader {
          logo {
            fluid(maxWidth:300) {
                ...GatsbyContentfulFluid
            }
          }
          phone
          email
          info
          facebookLink
        }
      }
    `)

    const logo = data.contentfulHeader.logo.fluid
    const { phone, email, info, facebookLink } = data.contentfulHeader
    return (
        <header>
            <div className="flex justify-between sm:flex-wrap sm:h-auto h-24">
                <div className="w-48 sm:h-16 mb-10">
                    <Img fluid={logo} />
                </div>
                <div className="font-light text-blue-800 text-md mx-4 mt-4">
                    <a href={facebookLink} aria-label="Jon Spencer Technical Drawing on Facebook" target="_blank" rel="noreferrer"><FaFacebookSquare /></a>
                    <div className="">Phone: <a href={`tel:${phone}`} className="font-medium">{phone}</a></div>
                    <div>Email: <a href={`mailto:${email}`}><span className="font-medium">{email}</span></a>  </div>
                </div>
            </div>
            <Navbar />
            <InfoBanner info={info} />
        </header>
    )
}

export default Header